// Terms.js
import React from 'react';
import './terms.css'; // Ensure the CSS styles match those in 'about.css', 'policy.css', etc.

function Terms() {
  return (
    <div className='flex-column space-between align-center'>
      <div id='terms-header-row' className='flex-row align-center'>
        <h1>Terms and Conditions</h1>
      </div>
      <section id='terms-introduction' className='panel'>
        <h2>Introduction</h2>
        <p>
          Briefly introduce the purpose of these terms and conditions and
          mention that by using the website or service, users are bound by these
          terms.
        </p>
      </section>

      <section id='use-of-service' className='panel'>
        <h2>Use of Service</h2>
        <p>
          Describe the proper and expected use of your service or website. This
          might include user conduct guidelines and prohibited activities.
        </p>
      </section>

      <section id='intellectual-property' className='panel'>
        <h2>Intellectual Property</h2>
        <p>
          Explain the ownership of intellectual property on your website,
          including content, logos, and trademarks.
        </p>
      </section>

      <section id='account-responsibility' className='panel'>
        <h2>Account Responsibility</h2>
        <p>
          If applicable, outline the responsibilities of the user in maintaining
          the confidentiality of account information and passwords.
        </p>
      </section>

      <section id='liability-limitation' className='panel'>
        <h2>Liability Limitation</h2>
        <p>
          State the limitations of your liability regarding the use of your
          website or service.
        </p>
      </section>

      <section id='governing-law' className='panel'>
        <h2>Governing Law</h2>
        <p>
          Indicate the governing law under which your terms and conditions are
          constructed and any disputes will be settled.
        </p>
      </section>

      <section id='amendments-to-terms' className='panel'>
        <h2>Amendments to Terms</h2>
        <p>
          Specify how and when the terms and conditions might be updated or
          amended, and how users will be informed of these changes.
        </p>
      </section>
    </div>
  );
}

export default Terms;

import React from 'react';
import { Link } from 'react-router-dom';
import SendEmail from '../../utils/sendEmail';
import './services.css';

function Services() {
  return (
    <div className='flex-column services'>
      <div id='service-header-row' className='flex-row align-center'>
        <h1>What Does Bastion Do?</h1>
      </div>
      <div id='service-tiers' className='flex-column'>
        <div className='panel service-tier'>
          <h2>Starter Package</h2>
          <p>
            Ideal for small businesses and startups, our Starter Package offers
            a comprehensive solution for those looking to establish their
            digital presence quickly and efficiently. This package includes:
          </p>
          <ul>
            <li>Basic website design and development</li>
            <li>Responsive mobile-friendly layout</li>
            <li>SEO essentials for better search engine rankings</li>
            <li>One month of post-launch support</li>
          </ul>
          <div id='service-1-actions-container' className='flex-row width-100'>
            <SendEmail id='send-email' text='$5,000' />
          </div>
        </div>
        <div className='panel service-tier'>
          <h2>Growth Package</h2>
          <p>
            Designed for growing businesses ready to expand their impact, our
            Growth Package builds upon the Starter by introducing advanced
            features and strategies. This package includes:
          </p>
          <ul>
            <li>Advanced website design with custom graphics</li>
            <li>Interactive features and user engagement tools</li>
            <li>Comprehensive SEO strategy and analytics integration</li>
            <li>Three months of post-launch support and maintenance</li>
          </ul>
          <div id='service-2-actions-container' className='flex-row width-100'>
            <SendEmail id='send-email' text='$25,000' />
          </div>
        </div>
        <div className='panel service-tier'>
          <h2>Enterprise Solutions</h2>
          <p>
            Our Enterprise Solutions are tailor-made to fit the unique needs of
            your organization. We bring cutting-edge technology and bespoke
            design to the table, ensuring your project stands out. This service
            includes:
          </p>
          <ul>
            <li>Fully customized website and application development</li>
            <li>Scalable architecture for high traffic websites</li>
            <li>Data analysis, CRM integration, and automation</li>
            <li>Ongoing support with a dedicated account manager</li>
          </ul>
          <div id='service-3-actions-container' className='flex-row width-100'>
            <p>
              As Enterprise Solutions are customized to your specific
              requirements, we invite you to{' '}
              <Link to='/contact'>contact us</Link> for a personalized quote.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;

import React from 'react';
import SendEmail from '../../utils/sendEmail';

function Splash() {
  return (
    <div
      id='splash-container'
      className='flex-column space-around align-center full-height'
    >
      <div id='header-brand' className='flex-column align-center'>
        <h1 id='company-name'>Bastion Ventures</h1>
        <section id='company-overview' className='panel'>
          <p id='company-description'>Fortune favors the prepared.</p>
        </section>
      </div>
      <div id='email-actions-container' className='flex-row full-width'>
        <SendEmail />
      </div>
    </div>
  );
}

export default Splash;

// Privacy.js
import React from 'react';
import './privacy.css'; // Ensure the CSS styles are consistent with 'about.css' and 'policy.css'

function Privacy() {
  return (
    <div className='flex-column space-between align-center'>
      <div id='privacy-header-row' className='flex-row align-center'>
        <h1>Privacy Policy</h1>
      </div>
      <section id='privacy-introduction' className='panel'>
        <h2>Introduction</h2>
        <p>
          This section should provide an overview of your privacy policy,
          emphasizing your commitment to protecting user data.
        </p>
      </section>

      <section id='information-collection-use' className='panel'>
        <h2>Information Collection and Use</h2>
        <p>
          Detail what types of personal information you collect (e.g., names,
          email addresses, etc.), how you collect it, and what you use it for.
        </p>
      </section>

      <section id='information-sharing-disclosure' className='panel'>
        <h2>Information Sharing and Disclosure</h2>
        <p>
          Explain under what circumstances, if any, you might share user data
          with third parties. Be clear about your practices and any conditions
          under which data may be disclosed.
        </p>
      </section>

      <section id='cookies-tracking' className='panel'>
        <h2>Cookies and Tracking Technologies</h2>
        <p>
          Describe any tracking technologies (like cookies) you use, what data
          they collect, and how they are used.
        </p>
      </section>

      <section id='data-protection-rights' className='panel'>
        <h2>Your Data Protection Rights</h2>
        <p>
          Inform users of their rights regarding their data, such as the right
          to access, correct, delete, or restrict use of their personal data.
        </p>
      </section>

      <section id='policy-updates' className='panel'>
        <h2>Policy Updates</h2>
        <p>
          State how and when the policy might be updated and how users will be
          informed of these changes.
        </p>
      </section>
    </div>
  );
}

export default Privacy;

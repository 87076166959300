import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import './app.css';
import About from './components/About/about';
import Contact from './components/Contact/contact';
import Footer from './components/Footer/footer';
import Header from './components/Header/header';
import Splash from './components/Splash/splash';
import Legal from './components/Legal/legal';
import Panel from './components/Panel/panel';
import Policy from './components/Policy/policy';
import Privacy from './components/Privacy/privacy';
import Services from './components/Services/services';
import Terms from './components/Terms/terms';

function App() {
  return (
    <Router>
      <div id='app-container' className='flex-column align-center app'>
        <Header id='header' />
        <main
          id='main-content'
          className='flex-column width-100 height-100 align-center'
        >
          <Routes>
            <Route path='/' element={<Splash id='splash-page' />} />
            <Route path='/about' element={<About id='about-page' />} />
            <Route path='/contact' element={<Contact id='contact-page' />} />
            <Route path='/legal' element={<Legal id='legal-page' />} />
            <Route path='/panel' element={<Panel id='panel-page' />} />
            <Route path='/policy' element={<Policy id='policy-page' />} />
            <Route path='/privacy' element={<Privacy id='privacy-page' />} />
            <Route path='/services' element={<Services id='services-page' />} />
            <Route path='/terms' element={<Terms id='terms-page' />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </main>
        <Footer id='footer' />
      </div>
    </Router>
  );
}

export default App;

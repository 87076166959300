import React from 'react';
import SendEmail from '../../utils/sendEmail';

function Contact() {
  return (
    <div id='contact-container' className='flex-column space-around align-center full-height'>
      <div id='contact-header' className='flex-column align-center'>
        <h1 id='contact-title'>Contact Us</h1>
        <section id='contact-info' className='panel'>
          <p id='contact-description'>For more information or inquiries, please email us!</p>
          <div id='contact-email-actions' className='flex-row full-width'>
            <SendEmail id='send-email' text='Get in Touch' />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Contact;

import React from 'react';

function SendEmail({ text = 'Contact Us' }) {
  const handleSendEmail = () => {
    const subject = encodeURIComponent('I have a question:');
    const body = encodeURIComponent('Please write your question here.');
    const mailtoLink = `mailto:hello@bastion.vc?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  return (
    <button
      onClick={handleSendEmail}
      aria-label={text}
      className='controls-btn btn-primary width-100'
    >
      {text}
    </button>
  );
}

export default SendEmail;

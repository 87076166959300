// Policy.js
import React from 'react';
import './policy.css'; // Ensure the CSS styles match those in 'about.css'

function Policy() {
  return (
    <div className='flex-column space-between align-center'>
      <div id='policy-header-row' className='flex-row align-center'>
        <h1>Bastion Ventures Policies</h1>
      </div>
      <section id='customer-service-policy' className='panel'>
        <h2>Customer Service Policy</h2>
        <p>
          At Bastion Ventures, we are committed to delivering exceptional
          value and professionalism. Our customer service policy ensures prompt
          response times, comprehensive support channels, and a dedication to
          fostering long-term partnerships with our clients.
        </p>
      </section>

      <section id='payment-billing-policy' className='panel'>
        <h2>Payment and Billing Policy</h2>
        <p>
          We accept various methods of payment, adhering to terms that
          facilitate streamlined operations and mutual growth. Our billing
          practices are transparent, with clear guidelines on payment schedules
          and terms to maintain a trustworthy relationship with our clients.
        </p>
      </section>

      <section id='return-refund-policy' className='panel'>
        <h2>Return and Refund Policy</h2>
        <p>
          Bastion Ventures adheres to a fair and transparent return and
          refund policy. We outline clear conditions under which returns and
          refunds are possible and provide detailed procedures for initiating
          such requests to ensure customer satisfaction.
        </p>
      </section>

      <section id='privacy-policy' className='panel'>
        <h2>Privacy Policy</h2>
        <p>
          Protecting our clients' data is paramount. Our privacy policy complies
          with relevant data protection laws like GDPR or CCPA, ensuring that
          customer information is handled responsibly and ethically.
        </p>
      </section>
    </div>
  );
}

export default Policy;

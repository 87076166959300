import React from 'react';
import './header.css';
import logo from '../../logo.svg';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className='app-header'>
      <div className='app-logo-container'>
        <Link to='/'>
          <img src={logo} className='app-logo' alt='Bastion Logo' />
        </Link>
      </div>
      <nav className='app-nav'>
        <ul>
          <li>
            <Link to='/about'>About</Link>
          </li>
          <li>
            <Link to='/services'>Services</Link>
          </li>
          <li>
            <Link to='/contact'>Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;

import React from 'react';

function About() {
  return (
    <div className='flex-column space-between align-center'>
      <div id='service-header-row' className='flex-row align-center'>
        <h1>Who is Bastion?</h1>
      </div>
      <section id='company-overview' className='panel'>
        <h2>About Bastion</h2>
        <p>
          Bastion Ventures was founded in 2019 with a mission to
          revolutionize the way early-stage SMBs approach technology and product
          strategy. At Bastion, we specialize in providing expert guidance in
          product strategy, user experience design, and custom development
          solutions. Our focus is on enabling businesses to transform their
          ideas into successful products that drive growth and innovation.
        </p>
      </section>
      <section id='founder-story' className='panel'>
        <h2>About Quinn</h2>
        <p>
          Product Strategist | Experienced Product Leader | Moonlight Developer
        </p>
        <p>
          NY, New York, USA |{' '}
          <a href='mailto:quinn@bastion.vc'>quinn@bastion.vc</a>
        </p>
        <p>
          Patrick Quinn, the visionary behind Bastion, is a dedicated product leader
          with a proven track record in driving product development and
          formulating innovative strategies. His expertise lies in leveraging
          Agile methodologies and best practices in engineering to craft
          solutions that center around customer needs, propelling business
          growth. Quinn's leadership and solution architecture skills have been
          instrumental in shaping the direction and success of Bastion.
        </p>
        <p>
          Under his guidance, Bastion has flourished by collaborating with
          early-stage SMBs, assisting them in defining their product vision,
          developing MVPs, and iterating based on user feedback. His approach
          has consistently led to transformative solutions that resonate with
          users and align with business objectives.
        </p>
        <p>
          Prior to founding Bastion, Quinn served as VP of Product at PayClearly and
          Director of Product at various other tech ventures, where he led teams
          through significant transitions, including the development of new
          platforms and expansion into new market verticals. His journey from a
          Lead Product Manager and Solutions Architect to the founder of Bastion is
          a testament to his passion for technology and commitment to excellence
          in the field of product management.
        </p>
      </section>
    </div>
  );
}

export default About;

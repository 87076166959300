// Legal.js
import React from 'react';
import './legal.css'; // Ensure the CSS styles are consistent with other components

function Legal() {
  return (
    <div className='flex-column space-between align-center'>
      <div id='legal-header-row' className='flex-row align-center'>
        <h1>Legal Information</h1>
      </div>
      <section id='legal-details' className='panel'>
        <p>
          <strong>Business Name:</strong> Bastion Ventures
        </p>
        <p>
          <strong>Goods or Services Description:</strong>
          A wide range of tech consulting services including web development, software solutions, and IT consulting.
        </p>
      </section>
    </div>
  );
}

export default Legal;

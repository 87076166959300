import React from 'react';
import './panel.css';

const Panel = ({ title, children }) => {
  return (
    <div className='panel'>
      <h2 className='panel-title'>{title}</h2>
      <div className='panel-content'>{children}</div>
    </div>
  );
};

export default Panel;

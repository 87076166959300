import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

function Footer() {
  return (
    <footer className='app-footer'>
      <nav className='footer-nav'>
        <ul>
          <li>
            <Link to='/policy'>Policies</Link>
          </li>
          <li>
            <Link to='/legal'>Legal</Link>
          </li>
          <li>
            <Link to='/terms'>Terms</Link>
          </li>
          <li>
            <Link to='/privacy'>Privacy</Link>
          </li>
        </ul>
      </nav>
      <div className='footer-info'>
        <p>&copy; {new Date().getFullYear()} Bastion Ventures LLC - All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
